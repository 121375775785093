import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-407919cd"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "detail-navbar"
};
import { ArrowRight } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import BackIcon from './back-icon.vue';
export default {
  __name: 'detail-navbar',
  props: {
    breadcrumb: {
      type: String,
      default: ''
    }
  },

  setup(__props) {
    const props = __props;
    const router = useRouter();

    const handleTo = path => {
      window.location.href = 'https://www.comentropy.org/';
    };

    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, {
        "separator-icon": _unref(ArrowRight)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
          to: {},
          onClick: handleTo
        }, {
          default: _withCtx(() => [_createTextVNode("首页")]),
          _: 1
        }), _createVNode(_component_el_breadcrumb_item, {
          to: {
            path: '/item-list'
          }
        }, {
          default: _withCtx(() => [_createTextVNode("下载中心")]),
          _: 1
        }), _createVNode(_component_el_breadcrumb_item, {
          class: "curr-breadcrumb"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(__props.breadcrumb), 1)]),
          _: 1
        })]),
        _: 1
      }, 8, ["separator-icon"])]);
    };
  }

};