import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-73d2b379"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "detail"
};
import { useRoute, useRouter } from 'vue-router';
import { getItemDetail } from '@/service/detail/detail.js';
import { onMounted, ref } from 'vue';
import DetailNavbar from './cpns/detail-navbar.vue';
import DetailContent from './cpns/detail-content.vue';
import detail404 from './cpns/detail-404.vue';
export default {
  __name: 'detail',

  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const detailInfo = ref({});
    onMounted(() => {
      const id = route.params.id;
      getItemDetail(id).then(res => {
        detailInfo.value = res.result;
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DetailNavbar, {
        breadcrumb: detailInfo.value.name
      }, null, 8, ["breadcrumb"]), detailInfo.value.name ? (_openBlock(), _createBlock(DetailContent, {
        key: 0,
        detailInfo: detailInfo.value
      }, null, 8, ["detailInfo"])) : _createCommentVNode("", true), !detailInfo.value.name ? (_openBlock(), _createBlock(detail404, {
        key: 1
      })) : _createCommentVNode("", true)]);
    };
  }

};