import request from '@/service/index'

// 获取图片
export function getItemDetail(id) {
  return request.get({
    url: '/item/' + id
  })
}

export function downloadIterm(id, data) {
  return request.post({
    url: `/item/${id}/download`,
    data
  })
}
